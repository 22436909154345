import {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode
} from 'react';

interface OnlineStatusContextProps {
  onlineStatus: boolean;
}

type OnlineStatusProps = {
  children: ReactNode;
};

const OnlineStatusContext = createContext<OnlineStatusContextProps | undefined>(
  undefined
);

export const OnlineStatusProvider = ({ children }: OnlineStatusProps) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(false);

  useEffect(() => {
    if (navigator.onLine) {
      setOnlineStatus(true);
    }
    const handleOffline = () => setOnlineStatus(false);
    const handleOnline = () => setOnlineStatus(true);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={{ onlineStatus }}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const context = useContext(OnlineStatusContext);
  if (context === undefined) {
    throw new Error('useOnlineStatus must be used within OnlineStatusProvider');
  }
  return context;
};

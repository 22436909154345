import './styles.scss';
import { useEnv } from '../../../context/EnvironmentContext';

export const EnvironmentBanner = () => {
  const env = useEnv();

  if (env?.environment && env.environment !== 'production') {
    return (
      <div
        className={`environment-indicator ${env.environment}`}
        role="presentation"
      >
        {env.environment}
      </div>
    );
  }
  return null;
};

import { InfoBanner } from '@partnerconnect-monorepo/types';
import { axios, mapToCamelCase } from '../../index';
import { useQuery } from 'react-query';

const getReportingInfoBanner = () =>
  axios
    .get(`${process.env.NX_REPORTING_APP_BE_BASE}/infobanner/`)
    .then<Array<InfoBanner>>((res) => mapToCamelCase(res.data))
    .catch(() => []);

export const useReportingInfoBanner = () =>
  useQuery(['REPORTING_INFOBANNER'], getReportingInfoBanner);

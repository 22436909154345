import { Suspense, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Loading } from '@wfp/ui';
import { useRoutesContext, useUserProfile } from '../../context';
import { EmptyLayout, FullLayout } from '../../Layout';
import { PrivateRoute } from './PrivateRoute';
import { useAuth } from 'react-oidc-context';
import { SIGN_IN_CALLBACK_RETURN_URL } from '../../constants';

export const RoutesList = () => {
  const { routes, defaultRoute } = useRoutesContext();

  const location = useLocation();

  const { user, isAuthenticated } = useAuth();

  const { isLoading } = useUserProfile();

  const safeDefaultPath = useMemo(
    () =>
      defaultRoute.path?.[0] === '/'
        ? defaultRoute.path.slice(1, defaultRoute.path.length)
        : defaultRoute.path,
    [defaultRoute]
  );

  useEffect(() => {
    if (isAuthenticated && location.pathname.includes('/auth/callback')) {
      window.location.replace(
        (user?.state as { [SIGN_IN_CALLBACK_RETURN_URL]?: string })?.[
          SIGN_IN_CALLBACK_RETURN_URL
        ] ??
          safeDefaultPath ??
          ''
      );
    }
  }, [isAuthenticated, location.pathname, safeDefaultPath, user?.state]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes?.map((r) => {
          const {
            path,
            component: Component,
            isPrivate,
            permissionCheck = null,
            fullLayout = true
          } = r;
          const safePath =
            path?.[0] === '/' ? path.slice(1, path.length) : path;

          const Layout = fullLayout ? FullLayout : EmptyLayout;
          if (isPrivate) {
            return (
              <Route
                key={safePath + ''}
                path={safePath}
                element={
                  <PrivateRoute permissionCheck={permissionCheck}>
                    <Layout>
                      <Component />
                    </Layout>
                  </PrivateRoute>
                }
              />
            );
          }
          return (
            <Route
              key={path + ''}
              path={safePath}
              element={
                <Layout>
                  <Component />
                </Layout>
              }
            />
          );
        })}
        {!isLoading && (
          <Route path="" element={<Navigate to={safeDefaultPath} />} />
        )}
      </Routes>
    </Suspense>
  );
};

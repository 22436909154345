import { createContext, PropsWithChildren, useContext } from 'react';
import { EnvironmentVariables } from '@partnerconnect-monorepo/types';

const EnvironmentContext = createContext<EnvironmentVariables | undefined>(
  undefined
);

export function EnvironmentProvider({
  environment,
  children
}: PropsWithChildren<{ environment: EnvironmentVariables }>) {
  const deployedOnRootPath =
    environment.reactAppBaseRoute === '' ||
    environment.reactAppBaseRoute === '/';
  return (
    <EnvironmentContext.Provider value={{ ...environment, deployedOnRootPath }}>
      {children}
    </EnvironmentContext.Provider>
  );
}

export function useEnv() {
  return useContext(EnvironmentContext) ?? ({} as EnvironmentVariables);
}

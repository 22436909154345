import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { UserProfile } from '@partnerconnect-monorepo/types';
import { getUserProfile } from '../../api/user';
import { getUserNameFromToken, isWfpUser } from '../../helpers';
import { useAuth } from 'react-oidc-context';
import { isEqual } from 'lodash';

type Action =
  | {
      type: 'setUserProfile';
      userProfile: UserProfile;
    }
  | {
      type: 'stopLoading';
    };

type Dispatch = (action: Action) => void;

interface State {
  userProfile: UserProfile | null;
  isWfpUser: boolean;
  isLoading: boolean;
  username: string;
}

interface UserProfileContextInterface extends State {
  userProfileDispatch: Dispatch;
}

const initialValues: State = {
  userProfile: null,
  isWfpUser: false,
  isLoading: true,
  username: ''
};

function userProfileReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setUserProfile': {
      return {
        ...state,
        userProfile: action.userProfile,
        isWfpUser: isWfpUser(action.userProfile?.roles),
        isLoading: false,
        username: action.userProfile?.firstName
          ? action.userProfile?.firstName
          : getUserNameFromToken() || state.username
      };
    }
    case 'stopLoading': {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
}

const UserProfileContext = createContext<
  UserProfileContextInterface | undefined
>(undefined);

function UserProfileProvider({
  performAuth,
  children
}: PropsWithChildren<{ performAuth?: boolean }>) {
  const { user } = useAuth();

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => userProfileReducer(state, action),
    {
      ...initialValues,
      username: user?.profile.given_name ?? initialValues.username
    }
  );

  const { userProfile, isLoading, isWfpUser, username } = state;

  const value: UserProfileContextInterface = {
    userProfile,
    isWfpUser,
    isLoading,
    userProfileDispatch: dispatch,
    username
  };

  const authenticate = useCallback(() => {
    if (user?.id_token && !user.expired) {
      return getUserProfile()
        .then((fetchedUserProfile) => {
          if (fetchedUserProfile) {
            !isEqual(userProfile, fetchedUserProfile) &&
              dispatch({
                type: 'setUserProfile',
                userProfile: fetchedUserProfile
              });
          } else dispatch({ type: 'stopLoading' });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return dispatch({ type: 'stopLoading' });
  }, [user?.expired, user?.id_token, userProfile]);

  useEffect(() => {
    if (performAuth) {
      authenticate();
    }
  }, [performAuth, authenticate]);

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
}

function useUserProfile() {
  const context = useContext(UserProfileContext);

  if (context === undefined) {
    throw new Error('useUserProfile must be used within UserProfileProvider');
  }

  return context;
}

export { UserProfileProvider, useUserProfile };

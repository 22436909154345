interface GroupInterface {
  [key: string]: string;
}

export enum Group {
  PartnerFLAEditor = 'PartnerFLAEditor',
  WfpFlaAdmin = 'WfpFlaAdmin',
  WfpSupport = 'WfpSupport',
  PartnerInvoicingFocalPoint = 'PartnerInvoicingFocalPoint'
}

export const GROUPS: GroupInterface = {
  PartnerFLAEditor: 'partner_fla_editor',
  WfpFlaAdmin: 'wfp_fla_admin',
  WfpSupport: 'wfp_support',
  PartnerInvoicingFocalPoint: 'partner_invoicing_focal_point'
};

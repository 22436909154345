import {
  APIFlaUserProfile,
  APIReportingUserProfile,
  UserProfile
} from '@partnerconnect-monorepo/types';
import { axios } from '../core';
import { getLocationBasedOnAdminBreakdown, mapToCamelCase } from '../helpers';
import { getReportingUserRoleOptions } from '../utils';

const getReportingUserProfile = () =>
  axios
    .get(`${process.env.NX_REPORTING_APP_BE_BASE}/profile/me`)
    .then<APIReportingUserProfile>((res) => mapToCamelCase(res.data))
    .catch(() => undefined);

const getFlaUserProfile = () =>
  axios
    .get(`${process.env.NX_FLA_APP_BE_BASE}/profile/me`)
    .then<APIFlaUserProfile>((res) => mapToCamelCase(res.data))
    .catch(() => undefined);

const mapReportingUserProfileToUserProfile = (
  reportingUserProfile: APIReportingUserProfile
): UserProfile => {
  const {
    // exclude country and partnerName
    country: reportingCountry,
    partnerName: reportingPartnerName,
    ...reporting
  } = reportingUserProfile;

  return {
    ...reporting,
    reportingRoles: reporting.roles,
    reportingCountry,
    reportingPartnerName,
    partnerships: reporting.partnerships.map(
      ({ activityTags: apiActivityTags, ...otherPartnershipProps }) => ({
        ...otherPartnershipProps,
        activityTags: apiActivityTags.map((activityTag) => ({
          ...activityTag,
          locations: activityTag.locations.map((location) =>
            getLocationBasedOnAdminBreakdown(location)
          )
        }))
      })
    ),
    canAssignWfpRolesList: getReportingUserRoleOptions(
      reporting.canAssignWfpRolesList
    ),
    canAssignPartnerRolesList: getReportingUserRoleOptions(
      reporting.canAssignPartnerRolesList
    ),
    allowedModules: {
      canAccessReporting: true,
      canAccessPartnerships: false
    }
  };
};

const mapFlaUserProfileToUserProfile = (
  flaUserProfile: APIFlaUserProfile
): UserProfile => {
  const {
    // exclude country and partnerName
    country: partnershipsCountry,
    partnerName: flaPartnerName,
    ...fla
  } = flaUserProfile;

  return {
    ...fla,
    flaRoles: fla.roles,
    partnershipsCountry,
    flaPartnerName,
    allowedModules: {
      canAccessReporting: false,
      canAccessPartnerships: true
    }
  };
};

const mergePartialUserProfiles = (
  reportingUserProfile: APIReportingUserProfile,
  flaUserProfile: APIFlaUserProfile
): UserProfile => ({
  ...mapReportingUserProfileToUserProfile(reportingUserProfile),
  ...mapFlaUserProfileToUserProfile(flaUserProfile),
  firstName: reportingUserProfile.firstName || flaUserProfile.firstName,
  lastName: reportingUserProfile.lastName || flaUserProfile.lastName,
  permissions: [
    ...reportingUserProfile.permissions,
    ...flaUserProfile.permissions
  ],
  roles: [...reportingUserProfile.roles, ...flaUserProfile.roles],
  allowedModules: {
    canAccessReporting: true,
    canAccessPartnerships: true
  }
});

export const getUserProfile = (): Promise<UserProfile | null> => {
  const reportingUserProfileRequest = getReportingUserProfile();
  const flaUserProfileRequest = getFlaUserProfile();

  return Promise.allSettled([
    reportingUserProfileRequest,
    flaUserProfileRequest
  ])
    .then((partialUserProfiles) => {
      const reportingUserProfile = partialUserProfiles[0],
        flaUserProfile = partialUserProfiles[1],
        isReportingUserProfilePresent =
          reportingUserProfile.status === 'fulfilled',
        isFlaUserProfilePresent = flaUserProfile.status === 'fulfilled';

      if (
        isReportingUserProfilePresent &&
        !!reportingUserProfile.value &&
        isFlaUserProfilePresent &&
        !!flaUserProfile.value
      ) {
        return mergePartialUserProfiles(
          reportingUserProfile.value,
          flaUserProfile.value
        );
      } else if (isReportingUserProfilePresent && reportingUserProfile.value) {
        return mapReportingUserProfileToUserProfile(reportingUserProfile.value);
      } else if (isFlaUserProfilePresent && flaUserProfile.value) {
        return mapFlaUserProfileToUserProfile(flaUserProfile.value);
      } else return null;
    })
    .catch(() => null);
};
